<template>

  <div class="campaigns_component">
    <confirmation-box
      greenColor="blue"
      class="archiveCampaign archiveBrand"
      id="archiveCampaign"
      :confirmCallback="archiveCampaign"
      confirmText="Yes"
      cancelText="No"
    >

      <template slot="header">
        <h2 v-if="getModalData.status">Archive Campaign</h2>
        <h2 v-else>Unarchive Campaign</h2>
      </template>

      <template slot="description">
        <p v-if="getModalData.status">All {{ getSiteDetails.agency_name }} links inside this campaign will also be archived but
          they will
          still be functional.</p>
        <p v-else>All {{ getSiteDetails.agency_name }} links inside this campaign will also be Unarchived.</p>
        <p v-else>All {{ getSiteDetails.agency_name }} links inside this campaign will also be Unarchived.</p>
        <p class="small_sub_text">
          Do you want to continue?
        </p>
        <p class="checkbox gray_checkbox">
          <input
            id="dontShowAgain"
            v-model="getArchiveStatus.status"
            type="checkbox"
          >
          <label for="dontShowAgain">Do not show this message again.</label>
        </p>

      </template>

    </confirmation-box>

    <transition name="slide">
      <!--<sidebar></sidebar>-->
      <div class="container">
        <div class="campaigns_component_inner">
          <div class="component_head d-flex justify-content-between">
            <div class="head_left">

              <ui-button
                :type="'add'"
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="'New Campaign'"
                :icon="'fal fa-plus'"
                :iconPosition="'right'"
                :iconBackground="'rgba(31, 33, 110, 0.35)'"
                :iconBorderRadius="'50%'"
                :pulseEffect="getProfile.onboarding == false && getProfile.onboarding_data['cta'] == false"
                v-if="getProfile.policy.can_add_campaigns"
                v-on:click="createCampaignModal()"
              />

            </div>
            <div class="head_right d-flex align-items-center justify-content-end w-100">

              <div
                class="datepicker_field rounded input_field datepicker_input datePicker_parent input_with_icon_right">
                <input
                  v-model="getCampaigns.date.label"
                  class="campaignDatePicker"
                  readonly
                  placeholder="Search by date..."
                  type="text"
                >
                <i class="input_icon fal fa-angle-down"></i>
              </div>
              <b-dropdown
                right
                class="ml-2 dropdown-menu-right default_style_dropdown checkbox_dropdown"
                :no-caret="true"
              >
                <div @click="fetchBrandOnDropdown"
                     class="dropdown_header rounded_shape d-flex align-items-center  "
                     slot="button-content"
                >
                  <span class="text">{{ getSelectedFilter('campaign', 'brand') }}</span>
                  <span class="arrow_icon ml-auto">
                    <i class=" fal fa-angle-down ml-3"></i>
                  </span>

                </div>
                <ul @change="selectedBrands()" id="brand-dropdown-scroll"
                    @scroll="lazyScrollBrand('brand-dropdown-scroll','fetchBrandList')"
                    class="inner ">
                  <!--//search....-->
                  <li class="list_item_li top-li-border">
                    <p class="checkbox_input_image ml-auto">
                      <input
                        id="select_all_campaigns"
                        v-model="getCampaigns.select_all"
                        @click="selectAllCampaigns()"
                        type="checkbox"
                      >
                      <label
                        style="color: #1f216e;"
                        for="select_all_campaigns"
                        class="checkbox_right"
                      >All Brands</label>
                    </p>

                  </li>

                  <template v-if="getComponentBrandList && getComponentBrandList.length">
                    <li v-for="brand in getComponentBrandList" class="list_item_li">
                      <div class="checkbox_input_image">
                        <input
                          type="checkbox"
                          :id="brand._id"
                          v-model="getCampaigns.selected_brands"
                          :value="brand._id"
                        >
                        <label
                          :for="brand._id"
                          class="checkbox_right"
                        >
                          <div class="user_block d-flex align-items-center">
                            <div class="user_picture">
                              <div
                                class="img"
                                :style="brandImageLink(brand.avatar)"
                              ></div>
                            </div>
                            <div class="user_content">
                              <p class="name">{{ limitTextLength(brand.name, 21) }}</p>
                            </div>
                          </div>
                        </label>
                      </div>
                    </li>
                    <li v-if="getBrandLoaders.retrieve" class="list_item_li">
                      <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
                    </li>
                  </template>
                  <li v-else-if="getBrandLoaders.retrieve" class="list_item_li mt-1">
                    <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
                  </li>
                  <li class="no_text_li" v-else-if="!getBrandList.length">
                    You do not have any brand.
                  </li>

                </ul>
              </b-dropdown>

              <b-dropdown
                right
                class="ml-2 dropdown-menu-right default_style_dropdown checkbox_dropdown"
                :no-caret="true"
              >
                <div
                  class="dropdown_header rounded_shape d-flex align-items-center  "
                  slot="button-content"
                >
                  <span class="text">Hide/Show Columns</span>
                  <span class="arrow_icon ml-auto">
                    <i class=" fal fa-angle-down ml-3"></i>
                  </span>

                </div>
                <ul class="inner ">
                  <!--//search....-->
                  <li class="list_item_li top-li-border">
                    <p class="checkbox_input_image ml-auto">
                      <input
                        id="select_all_columns"
                        @change="ctaColumnSelectAll()"
                        v-model="checkAllColumn"
                        type="checkbox"
                      >
                      <label
                        style="color: #1f216e;"
                        for="select_all_columns"
                        class="checkbox_right"
                      >All Columns</label>
                    </p>

                  </li>

                  <template v-for="(columns,index) in tableColumns">

                    <li class="list_item_li">
                      <div class="checkbox_input_image">
                        <input
                          @change="campaignsSelectedColumnsCount()"
                          type="checkbox"
                          v-model="columns.status"
                          :id="'column'+ columns.id"
                        >

                        <label
                          :for="'column'+ columns.id"
                          class="checkbox_right"
                        >
                          {{ columns.name }}
                        </label>
                      </div>
                    </li>

                  </template>

                </ul>
              </b-dropdown>

              <guided-tour v-if="getGuidedTour.step6"></guided-tour>
            </div>

          </div>

          <div class="white_box ">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center">
                <div class="left">
                  <h2 class="">Your Campaigns</h2>
                  <p class="">
                    A campaign allows you to create widgets for your brand advertisement. You can
                    create
                    campaigns to promote your branded message and collect leads for each of your
                    brands.
                  </p>
                </div>

                <div class="ml-auto right more_width_500 d-flex align-items-center justify-content-end">

                  <div
                    class="mr-2 search_input_block no_animation "
                    :class="{active_input : getCampaigns.search}"
                  >
                    <input
                      placeholder="Search campaign"
                      v-model="getCampaigns.search"
                      @input="campaignSearch"
                      type="text"
                      class=" "
                      @keyup.enter="fetchCampaignsList({ page: 1 })"
                    >
                    <i
                      class="fal fa-search"
                      @click.prevent="fetchCampaignsList({ page: 1 })"
                    ></i>
                  </div>

                  <div class="ml-2 toggle_btn_link with_border ">
                    <div class="toggle_btn_inner">
                      <a
                        @click.prevent="showCampaignArchive(false)"
                        class="btn_link"
                        :class="{'active_tab':!getCampaigns.archive}"
                      >
                        Active Campaigns
                      </a>
                      <a
                        @click.prevent="showCampaignArchive(true)"
                        class="btn_link"
                        :class="{'active_tab':getCampaigns.archive }"
                      >
                        Archived Campaigns
                      </a>

                    </div>
                  </div>

                  <!--<button v-if="getCampaigns.archive"-->
                  <!--@click.prevent="showCampaignArchive(false)"-->
                  <!--class="btn-&#45;&#45;cta btn-border btn-round  btn-bold">-->
                  <!--<span>Show Active Campaigns</span>-->
                  <!--</button>-->
                  <!--<button v-else @click.prevent="showCampaignArchive(true)"-->
                  <!--class="btn-&#45;&#45;cta btn-border btn-round  btn-bold">-->
                  <!--<span>Show Archived Campaigns</span>-->
                  <!--</button>-->

                </div>

              </div>
              <div class="box_content">

                <div class="table_container">
                  <table class="table list-content">

                    <thead class="list-content-head">
                    <campaigns-list-header :tableColumns="tableColumns"></campaigns-list-header>
                    </thead>

                    <tbody v-if="getCampaignLoaders.retrieve">
                    <tr>
                      <td colspan="9">
                        <p style="padding: 10px;">
                          <clip-loader :size="size" :color="color"></clip-loader>
                        </p>
                      </td>
                    </tr>
                    </tbody>
                    <tbody v-else-if="getComponentCampaignList.length === 0 ">
                    <tr>
                      <td colspan="9" class="">
                        <p class="no-rows"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{
                            getFilteredMessage('campaign', getCampaigns.search, getCampaigns.date.label, getCampaigns.archive, getCampaigns.selected_brands)
                          }}</p>
                      </td>
                    </tr>
                    </tbody>

                    <template v-else>
                      <campaigns-list :tableColumns="tableColumns"></campaigns-list>
                    </template>

                  </table>
                </div>
              </div>

            </div>
            <template v-if="!getCampaignLoaders.retrieve && (getCampaigns.total_items > getCampaigns.limit)">
              <pagination
                :clickedMethod="paginateCampaigns"
                :resultCount="getCampaigns.total_items"
                :itemsPerPage="getCampaigns.limit"
                :currentPage="getCampaigns.page"
                :totalPagesNumber="getCampaigns.total_pages"
              ></pagination>
            </template>

          </div>

        </div>
      </div>
    </transition>
  </div>

</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

@-moz-document url-prefix() {
  .white_box .box_inner .box_head .right.more_width_500 {
    min-width: 560px !important;
  }
}

.toggle_btn_inner {
  width: 104% !important;
}
</style>

<script>

import {mapActions, mapGetters} from 'vuex'
import CampaignsList from './table/CampaignsList'
import CampaignsListHeader from './table/CampaignsListHeader'
import Headerbar from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import Modal from '@/misc/Modal.vue'
import Dropdown from '@/ui/Dropdown.vue'
import ConfirmationBox from '@/ui/ConfirmationBox.vue'
import GuidedTour from '@/views/guided-tour/GuidedTour.vue'
import {campaignTypes, linkTypes, widgetTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'

export default ({

  'components': {
    Headerbar,
    Sidebar,
    Modal,
    Dropdown,
    GuidedTour,
    ConfirmationBox,
    CampaignsList,
    CampaignsListHeader
  },
  data: function () {
    return {
      size_small: '18px',
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      checkAllColumn: false,
      tableColumns: [
        {id: 1, name: 'Brand', status: false},
        {id: 2, name: 'CTA Type', status: false},
        {id: 3, name: 'Campaign Type', status: true},
        {id: 4, name: 'Replug Links', status: true},
        {id: 5, name: 'Created', status: true},
        {id: 6, name: 'Headline', status: false},
        {id: 7, name: 'Clicks', status: true},
        {id: 8, name: 'Conversions', status: true},
        {id: 9, name: 'Conv. rate', status: true},
        {id: 10, name: 'Unique Clicks', status: false}
      ],
      previousPage: 1,
      debounce: null
    }
  },
  mounted () {
    let self = this
    setTimeout(function () {
      self.callCampaignDatePicker()
    }, 500)
    this.tableColumns[3].name = this.getSiteDetails.agency_name + ' Links'
  },

  async created () {
    this.resetCampaignFilters(true)
    await this.fetchCampaignOnFetchedStatus()
    await this.fetchDomains()
    await this.fetchCampaignAnalytics()
    await this.fetchWidgets({page: -1})
    await this.fetchPixels()
  },

  computed: {

    ...mapGetters([
      'getCampaignsList',
      'getCampaigns',
      'getCampaignLoaders',
      'getGuidedTour',
      'getBrands',
      'getModalData',
      'getSubscription',
      'getComponentCampaignList',
      'getComponentBrandList',
      'getBrandLoaders'
    ])
  },

  methods: {

    ...mapActions([
      'fetchCampaignsList',
      'archiveCampaign',
      'setAllBrandsFetchStatus',
      'fetchCampaignAnalytics',
      'fetchBrandList',
      'fetchDomains',
      'fetchWidgets',
      'fetchPixels',
      'setComponentCampaignList'
    ]),
    createCampaignModal () {
      let limits = this.getSubscription.limits

      if (limits && limits.campaign && this.getComponentCampaignList.length >= this.getComponentCampaignList.limits.campaign) {
        setTimeout(() => {
          this.$bvModal.show('upgrade-subscription-modal')
        }, 100)
        this.alertMessage('You have reached maximum limit of your subscription plan, please upgrade your plan to unlock more features.', 'error')
        return false
      }
      this.$store.commit(widgetTypes.RESET_WIDGET)
      this.$store.commit(campaignTypes.RESET_CAMPAIGN_ADD)
      this.$store.commit(linkTypes.SET_LINK_PREVIEW_URL, '')
      this.$store.commit(linkTypes.SET_HAS_LINK_CUSTOM_MESSAGE, false)
      this.$store.commit(linkTypes.SET_LINK_CUSTOM_MESSAGE, 'Your message goes here')
      this.$store.commit(linkTypes.SET_LINK_CUSTOM_MESSAGE_HEADLINE, 'Your headline goes here')
      this.$router.push({name: 'create'})
    },
    async showCampaignArchive (value) {
      if (!this.getCampaignLoaders.retrieve) {
        this.$store.commit(campaignTypes.SET_CAMPAIGNS_ARCHIVE_VALUE, value)
        this.fetchCampaignByFilters()
      }
    },

    /**
     * Fetching the record on the basis of page number
     * @param page
     * @returns {Promise<void>}
     */
    async paginateCampaigns (page) {
      this.getCampaigns.page = page

      // if page is one then no need to check periodic
      if (page !== 1) {
        this.getCampaigns.periodic = this.isFetchedPeriodic(this.previousPage, page, this.getCampaigns.periodic)
      }

      // if isAllListFetched true from other source like dropdown then no need to set again isAllListFetched
      if (!this.getCampaigns.isAllListFetched) {
        // isAllListFetched will only be true only when all record fetched in periodic way
        this.getCampaigns.isAllListFetched = this.getCampaigns.periodic && (page >= this.getCampaigns.total_pages)
      }

      // if previous page is equal to current page then fetch only analytics
      if (this.previousPage === page) {
        this.fetchCampaignAnalytics()
        return
      }

      // setting the previousPage
      this.previousPage = JSON.parse(JSON.stringify(page))

      // if pagination is not in periodic way then get record from api
      if (!this.getCampaigns.periodic && !this.getCampaigns.isAllListFetched) {
        this.$store.commit(campaignTypes.SET_CAMPAIGN_LIST, [])
        await this.fetchCampaignsList({page: page})
        await this.setComponentCampaignList(this.getCampaignsList)
        this.fetchCampaignAnalytics()
        // resetting the periodic on page 1 and previousPage
        if (page === 1) {
          this.getCampaigns.periodic = true
          this.previousPage = 1
        }
        return
      }

      // chunck the local record if exist on the basis of pagination otherwise get from DB
      const list = this.getCampaignsList.slice((page - 1) * this.getCampaigns.limit, page * this.getCampaigns.limit)
      if (list && list.length) {
        await this.setComponentCampaignList(list)
        this.fetchCampaignAnalytics()
        return
      }

      // if no local record found then get from DB
      await this.fetchCampaignsList({page: page})
      await this.setComponentCampaignList(this.getCampaignsList.slice((page - 1) * this.getCampaigns.limit, page * this.getCampaigns.limit))
      this.fetchCampaignAnalytics()
    },

    columnsStatusChanged (status) {
      for (let i = 0; i < this.tableColumns.length; i++) {
        if (this.tableColumns[i].status === false) {
          this.checkAllColumn = false
        }
      }
    },

    callCampaignDatePicker () {
      let me = this
      $(document).ready(function () {
        $('.campaignDatePicker').daterangepicker({
          opens: 'left',
          parentEl: 'body',
          maxDate: moment(),
          autoUpdateInput: false,
          startDate: moment().startOf('day').subtract(1, 'hour'),
          endDate: moment().endOf('day').subtract(1, 'hour'),
          ranges: {
            ' All Time': [moment().startOf('day').subtract(1, 'hour'), moment().endOf('day').subtract(1, 'hour')],
            ' Today': [moment(), moment()],
            ' Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            ' Last 7 Days': [moment().subtract(6, 'days'), moment()],
            ' Last 30 Days': [moment().subtract(29, 'days'), moment()],
            ' This Month': [moment().startOf('month'), moment().endOf('month')],
            ' Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            ' Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
          }
        })
        $('.campaignDatePicker').on('show.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').addClass('shown')
        })

        $('.campaignDatePicker').on('hide.daterangepicker', function (ev, picker) {
          $(this).parent('.datePicker_parent').removeClass('shown')
        })

        $('.campaignDatePicker').on('apply.daterangepicker', function (ev, picker) {
          let range = null
          if (picker.chosenLabel != ' All Time') {
            range = {
              label: picker.chosenLabel,
              value: picker.startDate.format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + picker.endDate.format('YYYY-MM-DDTHH:mm:ss')
            }
          } else {
            range = {
              label: picker.chosenLabel,
              value: moment().subtract(5, 'years').format('YYYY-MM-DDTHH:mm:ss') + ' _ ' + moment().format('YYYY-MM-DDTHH:mm:ss')
            }
          }
          // calling the content mixing method to reflect the range filter.
          me.$store.commit(campaignTypes.SET_CAMPAIGNS_DATE_VALUE, range)
          me.fetchCampaignByFilters()
        })
      })
    },

    /**
     * Fetching the campaign on the basis of date, search, filters
     */
    async fetchCampaignByFilters () {
      this.getBrand.isListFetched = false
      this.getBrand.periodic = true
      this.getBrand.isAllListFetched = false
      this.previousPage = 1
      this.$store.commit(campaignTypes.SET_CAMPAIGN_LIST, [])
      this.$store.commit(campaignTypes.SET_COMPONENT_CAMPAIGN_LIST, [])
      await this.fetchCampaignsList({page: 1})
      await this.setComponentCampaignList(this.getCampaignsList)
      this.fetchCampaignAnalytics()
    },

    ctaColumnSelectAll () {
      if (this.checkAllColumn) {
        this.tableColumns.map(item => item.status = true)
      } else {
        this.tableColumns.map(item => item.status = false)
      }
    },
    async selectAllCampaigns () {
      const select = this.getCampaigns.select_all
      if (!select) {
        const brandIdsList = this.getComponentBrandList.map(item => item._id)
        await this.$store.commit(campaignTypes.SET_CAMPAIGN_BRAND_FILTERS, brandIdsList)
      } else {
        await this.$store.commit(campaignTypes.SET_CAMPAIGN_BRAND_FILTERS, [])
      }
      this.fetchCampaignAnalytics()
    },
    campaignsSelectedColumnsCount () {
      this.checkAllColumn = (this.tableColumns.filter(item => item.status === true).length === this.tableColumns.length)
    },
    selectedBrands () {
      this.fetchCampaignByFilters()
    },
    campaignSearch () {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.fetchCampaignByFilters()
      }, 600)
    }
  },
  watch: {
    'getCampaigns.selected_brands.length' (value) {
      const checkboxValue = (this.getComponentBrandList.length === value)
      this.$set(this.getCampaigns, 'select_all', checkboxValue)
    }
  }

})
</script>
