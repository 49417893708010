<template>
  <tbody class="list-content-body">

  <tr v-for="(campaign,index) in getComponentCampaignList" :index="index" :key="campaign._id">

    <td style="text-align: left;">
      <div :class="{'overlay_item archived':getCampaigns.archive}">
        <div class="inline_editor" v-if="campaign.edit === true">
          <input type="text"
                 @keyup.enter="editCtaNameAction(campaign,index)"
                 v-model="campaign.edit_value"
                 placeholder="Enter Campaign name here...">
          <span class="icon red"
                @click="closeEditCampaignName(campaign, index)"><i
            class="fal fa-times"></i></span>
          <clip-loader v-if="campaign.loaderStatus"
                       class="icon" style="right: 30px;" :size="size_small"
                       :color="color"></clip-loader>

          <span v-else-if="campaign.edit_value != ''" class="icon green"
                @click.prevent="editCtaNameAction(campaign,index)"><i
            class="far fa-check"></i></span>

        </div>
        <div class="cta_name" v-else>
          <span class="max_width_220 word_break" v-if="campaign.name"
                v-tooltip="campaign.name">
            {{ limitTextLength(campaign.name, 34) }}
            <span v-if="campaign.incomplete" class="status_bar gray">
              Incomplete
            </span>
          </span>
          <span v-else>
            Untitled
          </span>
          <span v-if="campaign.policy.edit" class="icon"
                @click="editCampaignName(campaign, index)"><i
            class="far fa-pencil"></i></span>
        </div>
      </div>

    </td>
    <td v-if="tableColumns[0].status" width="350px">
      <div :class="{'overlay_item archived':getCampaigns.archive}"
           class="align_top">
        <div class="avatar">
          <div class="brand_img"
               :style="brandImageLink(campaign.brand.avatar)"></div>
        </div>
        <div class="content">
          <div class="name max_width_220 word_break" v-tooltip="campaign.brand.name">
            {{ limitTextLength(campaign.brand.name, 34) }}
          </div>
          <div class="clearfix"></div>
          <div class="url" v-tooltip="campaign.brand.url">
            {{ campaign.brand.url }}
          </div>
        </div>
      </div>

    </td>

    <td v-if="tableColumns[2].status">
      <div :class="{'overlay_item archived':getCampaigns.archive}">
        <span style="text-transform: capitalize;">{{ findCampaignType(campaign) }}</span>
      </div>

    </td>

    <td v-if="tableColumns[1].status">
      <div :class="{'overlay_item archived':getCampaigns.archive}">
                                           <span v-if="campaign" style="text-transform: capitalize;"
                                                 :class="{'light_grey' : campaign.type === 'no_cta'
                                                  || campaign.type === 'retargeting' || campaign.type === 'integration'}">
                                                <template
                                                  v-if="campaign.type === 'no_cta' || campaign.type === 'retargeting' || campaign.type === 'integration'">
                                                    N/A
                                                </template>
                                                <template v-else>
                                                     {{ campaign.type }}
                                                </template>
                                            </span>
        <span v-else style="color: red;">Missing</span>
      </div>

    </td>

    <td v-if="tableColumns[5].status">
      <div :class="{'overlay_item archived':getCampaigns.archive}">
                                            <span v-if="campaign">
                                                <span v-if="campaign.type !== 'retargeting' && campaign.type !== 'integration'
                                                && campaign.cta_type !== 'social_modern' && campaign.type !== 'no_cta'">
                                                        {{ campaign.message_headline }}
                                                </span>
                                                 <span class="light_grey" v-else>N/A</span>
                                            </span>
        <span v-else style="color: red;">Missing</span>
      </div>

    </td>

    <template v-if="tableColumns[3].status">
      <td class="brand_cta" v-if="campaign.total_links">
        <div :class="{'overlay_item archived':getCampaigns.archive}">
          {{ commaSeparatedNumber(campaign.total_links) }}
        </div>

      </td>
      <td class="brand_cta" v-else>
        <div :class="{'overlay_item archived':getCampaigns.archive}">
          0
        </div>

      </td>
    </template>

    <td v-if="tableColumns[6].status" class="text-center">
      <div :class="{'overlay_item archived':getCampaigns.archive}" v-if="!campaign.fetchStats">
        {{ commaSeparatedNumber(campaign.total_visitors) }}
      </div>
      <skeleton v-else></skeleton>
    </td>

    <td v-if="tableColumns[9].status" class="text-center">
      <div :class="{'overlay_item archived':getCampaigns.archive}" v-if="!campaign.fetchStats">
        {{ commaSeparatedNumber(campaign.unique_visitors) }}
      </div>
      <skeleton v-else></skeleton>
    </td>

    <td v-if="tableColumns[7].status" class="text-center">
      <div :class="{'overlay_item archived':getCampaigns.archive}" v-if="!campaign.fetchStats">
        {{ commaSeparatedNumber(campaign.total_clicks_and_conversions) }}
      </div>
      <skeleton v-else></skeleton>
    </td>

    <td v-if="tableColumns[8].status" class="text-center">
      <div :class="{'overlay_item archived':getCampaigns.archive}" v-if="!campaign.fetchStats">
        {{ campaign.unique_visitors_conversion_rate }}%
      </div>
      <skeleton v-else></skeleton>
    </td>

    <td v-if="tableColumns[4].status" class="created_at">
      <div :class="{'overlay_item archived':getCampaigns.archive}">
        {{ campaign.created_at | relativeCaptilize }}
      </div>

    </td>

    <td class="text-right ">

      <div class="actions text-center" v-if="campaign.archive">
        <template v-if="campaign.policy.edit">

          <i v-tooltip="'Unarchive'" class="far fa-recycle" data-cy="unarchive"
             @click.prevent="archiveCampaignModal(campaign._id,index,campaign.user_id, false)"
             aria-hidden="true"></i>

        </template>
        <template v-else>
          -
        </template>
      </div>
      <template v-else>

        <b-dropdown ref="dropdownActionMenu" right offset="-30"
                    class="table_action_dropdown dropdown-menu-right default_style_dropdown"
                    :no-caret="true">
          <!--d-flex align-items-center-->
          <div class=" dropdown_header no_arrow text-center" slot="button-content">
                         <span class="text">
                             <i class="fal fa-ellipsis-h fw-500"></i>
                         </span>
            <!--<span class="arrow_icon ml-auto">-->
            <!--<i class=" fal fa-angle-down ml-3"></i>-->
            <!--</span>-->
          </div>
          <ul class="clear inner ">
            <li class="list_item">
              <a @click.prevent="campaignDetailView(campaign.brand, campaign)">
                <i class="far fa-line-chart" data-cy="view-stats"></i> View Stats
              </a>
            </li>

            <template>
              <li class="list_item" v-if="campaign.policy.edit">
                <a @click.prevent="editCampaign(campaign)"
                   target="_blank" href="" data-cy="edit-camp">
                  <i class="far  fa-pencil"
                     aria-hidden="true"></i> Edit
                </a>
              </li>
              <li class="list_item" v-if="campaign.policy.edit">
                <a @click.prevent="duplicateCampaign(campaign)"
                   target="_blank" href="">
                  <i class="fal  fa-files-o" aria-hidden="true" data-cy="duplicate"></i>
                  Duplicate
                </a>
              </li>
              <li class="list_item" v-if="campaign.policy.archive">
                <a @click.prevent="archiveCampaignModal(campaign._id,index,campaign.user_id,true)"
                   target="_blank" href="">
                  <i class="far  fa-recycle" aria-hidden="true" data-cy="c-archive"></i>
                  Archive
                </a>
              </li>
              <li class="list_item" v-if="campaign.policy.delete">
                <a @click.prevent="deleteCampaign({campaignId: campaign._id,index: index})"
                   target="_blank" href="">
                  <i class="far  fa-trash" aria-hidden="true" data-cy="remove"></i>
                  Remove
                </a>
              </li>
            </template>

          </ul>

        </b-dropdown>

      </template>
    </td>
  </tr>
  </tbody>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import Dropdown from '@/ui/Dropdown.vue'
import {campaignTypes, userTypes} from '@/state/modules/mutation-types'

export default ({
  components: {
    Dropdown
  },
  props: {
    tableColumns: {}
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'getCampaigns',
      'getComponentCampaignList'
    ])
  },

  methods: {
    ...mapActions(['deleteCampaign', 'storeCampaignName']),
    archiveCampaignModal (campaign, index, userId, status) {
      this.$store.commit(userTypes.SET_MODAL_DATA, {
        campaign: campaign,
        user_id: userId,
        status: status,
        index: index
      })
      if (this.getProfile.hide_archive_message) {
        this.$store.dispatch('archiveCampaign')
      } else {
        $('#archiveCampaign').modal('show')
      }
    },
    editCampaignName (campaign, index) {
      this.$set(this.getComponentCampaignList[index], 'edit', true)
      this.$set(this.getComponentCampaignList[index], 'edit_value', (campaign.name) ? campaign.name : 'Untitled')
      // campaign.edit = true
      // campaign.edit_value = (campaign.name) ? campaign.name : 'Untitled'
      // this.getCampaigns.list.splice(index, 1, campaign)
      // this.$store.commit(campaignTypes.EDIT_CAMPAIGN_NAME, campaignId)
    },

    closeEditCampaignName (campaign, index) {
      campaign.edit = false
      this.$set(this.getComponentCampaignList[index], 'edit', false)
      // this.getCampaigns.list.splice(index, 1, campaign)
    },

    async editCtaNameAction (campaign, index) {
      if (campaign.edit_value && (campaign.edit_value.trim()).length <= 0) {
        this.$store.dispatch('toastNotification', {message: 'Campaign name is required.', type: 'error'})
        return
      }
      if (!this.maxLength(campaign.edit_value, 100)) {
        this.$store.dispatch('toastNotification', {
          message: 'Campaign name should not be greater than 100 characters.',
          type: 'error'
        })
        return
      }

      let res = await this.storeCampaignName({campaign: campaign, index: index})
      if (res.status) {
        campaign.name = campaign.edit_value.trim()
        campaign.edit = false
        this.getComponentCampaignList.splice(index, 1, campaign)
      } else {
        this.$store.dispatch('toastNotification', {message: res.message, type: 'error'})
      }

    },
    findCampaignType (campaign) {
      switch (campaign.type) {
        case 'no_cta':
          return 'Shortener'
        case 'retargeting':
          return 'Retargeting'
        case 'integration':
          return '3rd Party Widget'
        default:

          if (campaign.campaign_type && campaign.campaign_type.retargeting === true) {
            return 'Call-to-Action + Retargeting'
          } else if (campaign.campaign_type) {
            return 'Call-to-Action'
          } else {
            return 'N/A'
          }
      }
    },
    editCampaign (campaign) {
      this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EDIT_VALUE, campaign)
      this.$router.push({name: 'campaign_type'})
    },
    duplicateCampaign (campaign) {
      let newCampaign = JSON.parse(JSON.stringify(campaign))
      newCampaign.total_links = 0
      newCampaign.total_visitors = 0
      newCampaign.conversions = 0
      newCampaign.unique_visitors = 0
      newCampaign.total_clicks = 0
      newCampaign.total_clicks_and_conversions = 0
      newCampaign.conversion_rate = 0
      newCampaign.unique_visitors_conversion_rate = 0
      newCampaign.clicks = 0
      newCampaign.name = newCampaign.name + ' - Duplicate'
      delete newCampaign.created_at
      delete newCampaign.updated_at
      newCampaign._id = null
      this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EDIT_VALUE, newCampaign)
      this.$router.push({name: 'campaign_type'})
    }
  }
})
</script>

<style scoped lang="less">
.cta_name {
  span {
    cursor: pointer;
  }
}
</style>
