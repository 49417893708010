<template>

    <tr>

        <th class="" style="width: 360px">Name</th>
        <th v-if="tableColumns[0].status" class="" style="width: 260px">Brand</th>
        <th v-if="tableColumns[2].status" class="" style="width: 260px">Campaign Type</th>
        <th v-if="tableColumns[1].status" class="" style="width: 130px;">CTA Type</th>

        <th v-if="tableColumns[5].status" class="" style="width: 200px;">Headline</th>

        <th v-if="tableColumns[3].status" class="brand_cta cursor_pointer sorted_row"
            style="width: 200px;"
            @click.prevent="changeCampaignSorting('link')">{{ getSiteDetails.agency_name }} Links
            <i v-if="getCampaigns.sort === 'link_asc'"
               class="first table_filter_caret fas fa-caret-up"></i>
            <i v-else-if="getCampaigns.sort === 'link_desc'"
               class="table_filter_caret fas fa-caret-down"></i>
        </th>
        <th v-if="tableColumns[6].status" class="text-center"
            style="width: 200px;"
            >Clicks
<!--          @click.prevent="changeCampaignSorting('click')"-->
<!--            <i v-if="getCampaigns.sort === 'click_asc'"-->
<!--               class="first table_filter_caret fas fa-caret-up"></i>-->
<!--            <i v-else-if="getCampaigns.sort === 'click_desc'"-->
<!--               class="table_filter_caret fas fa-caret-down"></i>-->
        </th>

        <th v-if="tableColumns[9].status" class="text-center"
            style="width: 200px;"
            >Unique
            Clicks
<!--          @click.prevent="changeCampaignSorting('unique')"-->
<!--            <i v-if="getCampaigns.sort === 'unique_asc'"-->
<!--               class="first table_filter_caret fas fa-caret-up"></i>-->
<!--            <i v-else-if="getCampaigns.sort === 'unique_desc'"-->
<!--               class="table_filter_caret fas fa-caret-down"></i>-->
        </th>

        <th v-if="tableColumns[7].status" class="text-center"
            style="width: 200px;"
            >Conv.
<!--          @click.prevent="changeCampaignSorting('conversion')"-->
<!--            <i v-if="getCampaigns.sort === 'conversion_asc'"-->
<!--               class="first table_filter_caret fas fa-caret-up"></i>-->
<!--            <i v-else-if="getCampaigns.sort === 'conversion_desc'"-->
<!--               class="table_filter_caret fas fa-caret-down"></i>-->
        </th>
        <th v-if="tableColumns[8].status" class="text-center"
            style="width: 200px;"
            >Conv.
            rate
<!--          @click.prevent="changeCampaignSorting('conversion_rate')"-->
<!--            <i v-if="getCampaigns.sort === 'conversion_rate_asc'"-->
<!--               class="first table_filter_caret fas fa-caret-up"></i>-->
<!--            <i v-else-if="getCampaigns.sort === 'conversion_rate_desc'"-->
<!--               class="table_filter_caret fas fa-caret-down"></i>-->
        </th>

        <th v-if="tableColumns[4].status" class="cursor_pointer sorted_row"
            style="width: 150px;"
            @click.prevent="changeCampaignSorting('created')">Created
            <i v-if="getCampaigns.sort === 'created_asc'"
               class="first table_filter_caret fas fa-caret-up"></i>
            <i v-else-if="getCampaigns.sort === 'created_desc'"
               class="table_filter_caret fas fa-caret-down"></i>
        </th>
        <th style="width: 150px;" class="text-center">Actions</th>

    </tr>

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { campaignTypes } from '@/state/modules/mutation-types'

export default ({
  props: {
    tableColumns: {}
  },
  computed: {
    ...mapGetters(['getCampaigns'])
  },
  methods: {
    ...mapActions(['fetchCampaignsList', 'fetchCampaignAnalytics']),
    async changeCampaignSorting (type) {
      let sort = this.getCampaigns.sort
      let sortValue
      switch (type) {
        case 'link':
          sortValue = (sort === 'link_desc') ? 'link_asc' : 'link_desc'
          break
        case 'click':
          sortValue = (sort === 'click_desc') ? 'click_asc' : 'click_desc'
          break
        case 'conversion':
          sortValue = (sort === 'conversion_desc') ? 'conversion_asc' : 'conversion_desc'
          break
        case 'conversion_rate':
          sortValue = (sort === 'conversion_rate_desc') ? 'conversion_rate_asc' : 'conversion_rate_desc'
          break
        case 'created':
          sortValue = (sort === 'created_desc') ? 'created_asc' : 'created_desc'
          break
        case 'unique':
          sortValue = (sort === 'unique_desc') ? 'unique_asc' : 'unique_desc'
          break
      }
      this.$store.commit(campaignTypes.SET_CAMPAIGNS_SORT_VALUE, sortValue)
      await this.$parent.fetchCampaignByFilters()
    }
  }
})
</script>
